import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactYouTube from 'react-youtube';

const getID = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);

  return (match && match[7].length === 11) ? match[7] : false;
}

const S = {};
S.Youtube = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  
  iframe {
    position:absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

const YouTube = ({
  url,
  ...rest
}) => {

  const id = getID(url);

  return (
    <S.Youtube>
      <ReactYouTube
        videoId={id}
        {...rest}
      />
    </S.Youtube>
  );
};

YouTube.propTypes = {
  url: PropTypes.string.isRequired,
};

export default YouTube;
